import React from "react"
import { Formik, Form, Field, ErrorMessage } from 'formik'

import axios from 'axios'

const config = {
	customerApi : "http://127.0.0.1:3000/customer-gdpr",
	stripe_apiKey: "pk_test_iZciBohuPBV7pIX9qRSHsfQ9"
}



async function makeRequest(url, method, data){
    let response
    try {
      const params = new URLSearchParams()

      Object.keys(data).map( (key) => {
        params.append(key, data[key])
      } )

      response = await axios({
        method: method,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        url: url,
        params: params
      });
    }
    catch(e){
      console.error(e)
      throw e
    }

    return response.data
  }

  async function onSubmit(data) {

    console.log('Data',data)
    const responseData = await makeRequest("https://script.google.com/macros/s/AKfycbxl2LG2mUM_RL6RKh8hb-0ZcQLQqUZ4RjwpmauSdHDXqIFZRkM/exec", "POST", data)
    if (responseData) {
			return "Your request has been sent, we will respond you as soon as possible."
    }
    console.log("************responseData*********", responseData)
}

export default () => (
  <Formik
    initialValues={{
      name: '',
      email: '',
      message: '',
    }}
    onSubmit={async (values, actions) => {
      let response
      try {
        response = await onSubmit(values);
      } catch (error) {
        response = "There has been an error.  Please try again later."
        actions.setStatus("error");
      }
      actions.setStatus("success");
      return response
    }}
    validate={values => {
        const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
        const errors = {};
        if(!values.name) {
            errors.name = 'Name Required'
        }
        if(!values.email || !emailRegex.test(values.email)) {
            errors.email = 'Valid Email Required'
        }
        if(!values.message) {
            errors.message = 'Message Required'
        }
        return errors;
    }}
  >
  {({ isSubmitting, dirty, status }) => {
    if(status == "success"){
      return (
        <>
        <h2>Thanks getting in touch with us!</h2>
        <h2>Someone will contact you shortly.</h2>
        </>
      )
    }
    
    return (
      <Form className="form grid grid-cols-2 gap-x-10 gap-y-4">
        <div className="col-span-2">
          <label htmlFor="name">Your Name</label>
          <Field type="text" name="name" />
          <ErrorMessage className="error" component="div" name="name" />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="email">Email: </label>
          <Field type="email" name="email" />
          <ErrorMessage className="error" component="div" name="email" />
        </div>
        <div className="col-span-2 sm:col-span-1">
          <label htmlFor="phone">Phone: </label>
          <Field type="text" name="phone" />
          <ErrorMessage className="error" component="div" name="phone" />
        </div>

        <div className="col-span-2">
          <label htmlFor="role">How Can We Help? </label>
          <select className="w-full" name="role" tabindex="0">
            <option value="">- Please select an option -</option>
            <option value="site-sell-rooms">I want to use Arise at my Hotel</option>
            <option value="connection-request">I want to connect to Arise</option>
            <option value="learn-more">I want to learn more about Arise</option>
            <option value="Other">Other</option>
          </select>
          <ErrorMessage className="error" component="div" name="role" />
        </div>
        <div className="col-span-2">
          <label htmlFor="business">Name of Your Business</label>
          <Field type="text" name="business" />
          <ErrorMessage className="error" component="div" name="business" />
        </div>
        <div className="col-span-2">
          <label htmlFor="message">Message: </label>
          <Field textarea name="message" component="textarea"/>
          <ErrorMessage className="error" component="div" name="message" />
        </div>
        <button disabled={isSubmitting ? 'disabled' : ''} className="rounded-md text-lg font-medium px-10 py-3 mt-6 text-white bg-primary hover:no-underline col-span-2 sm:col-span-1" type="submit">Send</button>
      </Form>
    )}
  }
  </Formik>
)