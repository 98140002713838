import React from 'react';

const Button = ({ text, type, path, className }) => {
  return (
    <a href={path}>
      <button
        type={type}
        className={`bg-primary text-white font-medium rounded-md px-16 py-3 text-md ${className}`}
      >
        {text}
      </button>
    </a>
  );
};

export default Button;
