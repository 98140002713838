import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/Layout';
import Hero from '../components/Hero';
import Content from '../components/Content';
import SEO from '../components/SEO';

export const pageQuery = graphql`
  query PageQuery($id: String) {
    contentfulLayout(id: { eq: $id }) {
      title
      path
      index
      primaryColor
      hero {
        title {
          raw
        }
        subheading {
          raw
        }
        image {
          title
          svg {
            content
          }
        }
        callToAction
        callToActionUrl
      }
      bodySections {
        title
        tag
        mux {
          playbackId
        }
        media {
          title
          svg {
            content
          }
          fixed(height: 1080) {
            src
          }
        }
        text {
          raw
        }
        sections {
          title
          callToAction
          callToActionUrl
          description {
            raw
          }
          image {
            title
            svg {
              content
            }
          }
        }
        sectionType
      }
    }
  }
`;

const PageTemplate = ({ data }) => {
  const pageData = data.contentfulLayout;
  return (
    <Layout primaryColor={pageData.primaryColor}>
      <SEO title={pageData.title} />
      {pageData.hero && (
        <Hero
          isIndex={pageData.index}
          tag={pageData.title}
          primaryColor={pageData.primaryColor}
          data={pageData.hero}
        />
      )}
      <Content bodySections={pageData.bodySections} />
    </Layout>
  );
};

export default PageTemplate;
