import React from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { Link, useStaticQuery, graphql } from 'gatsby';

import Video from './Video.js';
import Contact from './Contact.js';

const renderDocument = (document) => {
  const Text = ({ children }) => <p>{children}</p>;

  const options = {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.LIST_ITEM]: (node, children) => {
        const UnTaggedChildren = documentToReactComponents(node, {
          renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => children,
            [BLOCKS.LIST_ITEM]: (node, children) => children,
          },
        });

        return <li>{UnTaggedChildren}</li>;
      },
    },
    renderText: (text) =>
      text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
  };

  return documentToReactComponents(JSON.parse(document), options);
};

const Content = ({ bodySections }) => {
  if (!bodySections) {
    return null;
  }

  const content = bodySections.map((bodySection) => {
    switch (bodySection.sectionType) {
      case 'media':
        if (!bodySection.media || !bodySection.mux) {
          return null;
        }

        if (bodySection.mux?.playbackId) {
          return (
            <div id={bodySection.tag} className="media">
              <div className="bg-circle-left"></div>
              <Video
                src={`https://stream.mux.com/${bodySection.mux.playbackId}.m3u8`}
                poster={bodySection.media?.fixed.src}
              />
            </div>
          );
        }

      case 'inline':
        const top_image = bodySection.media?.svg?.content;
        return (
          <div id={bodySection.tag} className="inline mx-auto py-16">
            <div className="title text-left sm:text-center">
              {bodySection.title}
            </div>
            {top_image && (
              <div
                className="image py-8 mx-auto staggered"
                dangerouslySetInnerHTML={{ __html: top_image }}
                alt={bodySection.media?.title}
              />
            )}
            <div
              className={`flex flex-col justify-items-center align-items-center md:align-items-center md:justify-items-start md:grid md:grid-flow-col md:grid-rows-3-auto gap-8 mx-auto py-16 ${
                top_image && 'staggered pt-0'
              }`}
            >
              {bodySection.sections.map((section) => {
                const image = section.image?.svg.content;
                return (
                  <>
                    <div
                      className="w-1/3 md:w-1/4"
                      dangerouslySetInnerHTML={{ __html: image }}
                      alt={section.image?.title}
                    />
                    <div
                      className={`text-2xl font-medium ${
                        top_image && 'justify-self-center'
                      }`}
                    >
                      {section.title}
                    </div>
                    <div>{renderDocument(section.description.raw)}</div>
                  </>
                );
              })}
            </div>
          </div>
        );

      case 'inline-cta':
        return (
          <div className="inline-cta mx-auto sm:py-16">
            <div className="bg-circle-right"></div>
            <div className="title text-center">{bodySection.title}</div>
            <div className="mx-auto py-16 flex flex-col md:flex-row md:space-x-6">
              {bodySection.sections.map((section) => {
                const image = section.image?.svg.content;

                let ctaButton;

                if (section.callToAction) {
                  ctaButton = (
                    <a
                      href={section.callToActionUrl}
                      className="self-start rounded-md text-primary text-lg font-medium px-10 py-3 bg-white hover:no-underline"
                    >
                      {section.callToAction}
                    </a>
                  );
                }
                return (
                  <div className="section flex flex-col flex-1 mb-6">
                    <div className="flex flex-col flex-1">
                      {section.title && (
                        <div className="tag mb-10">{section.title}</div>
                      )}
                      {image && (
                        <div
                          className="image"
                          dangerouslySetInnerHTML={{ __html: image }}
                          alt={section.image.title}
                        />
                      )}
                      {renderDocument(section.description.raw)}
                    </div>
                    {ctaButton}
                  </div>
                );
              })}
            </div>
          </div>
        );

      case 'cta':
        return (
          <div className="cta mx-auto sm:py-16">
            <div className="mx-auto">
              {bodySection.sections.map((section) => {
                const image = section.image?.svg.content;

                let ctaButton;

                if (section.callToAction) {
                  ctaButton = (
                    <a
                      href={section.callToActionUrl}
                      className="rounded-md text-lg font-medium px-10 py-3 text-white bg-primary hover:no-underline"
                    >
                      {section.callToAction}
                    </a>
                  );
                }
                return (
                  <div className="section bg-arise mb-6">
                    <div className="title text-center">{bodySection.title}</div>
                    {image && (
                      <div
                        className="image"
                        dangerouslySetInnerHTML={{ __html: image }}
                        alt={section.image.title}
                      />
                    )}
                    {renderDocument(section.description.raw)}
                    {ctaButton}
                  </div>
                );
              })}
            </div>
          </div>
        );

      case 'contact':
        return (
          <div className="contact bg-arise">
            <div className="title text-center">{bodySection.title}</div>
            <Contact />
          </div>
        );

      case 'staggered':
        return (
          <>
            <div className="bg-circle-left"></div>
            <div className="bg-circle-right"></div>
            <div className="staggered mx-auto py-16">
              {bodySection.title && (
                <div className="title text-center">{bodySection.title}</div>
              )}
              <div className="mx-auto py-16 grid grid-cols-1 gap-12 md:gap-24">
                {bodySection.sections.map((section, index) => {
                  const image = section.image?.svg?.content;

                  let ctaButton;

                  if (section.callToAction) {
                    ctaButton = (
                      <a
                        href={section.callToActionUrl}
                        className="self-start rounded-md text-primary text-lg font-medium px-10 py-3 bg-white hover:no-underline"
                      >
                        {section.callToAction}
                      </a>
                    );
                  }
                  return (
                    <div
                      className={`flex flex-col ${
                        index % 2
                          ? 'md:flex-row'
                          : 'md:flex-row-reverse md:space-x-reverse lg:flex-row-reverse lg:space-x-reverse'
                      } sm:space-x-12 lg:space-x-20`}
                    >
                      <div
                        className="image"
                        dangerouslySetInnerHTML={{ __html: image }}
                        alt={section.image.title}
                      />
                      <div className="section flex flex-col md:w-1/2">
                        <div
                          className={`self-start  ${
                            bodySection.title ? 'text-xl' : 'title'
                          } font-medium pt-8 pb-6`}
                        >
                          {section.title}
                        </div>
                        {renderDocument(section.description.raw)}
                        {ctaButton}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </>
        );

      case 'longform':
        return (
          <div className="longform content mx-auto py-16">
            <div className="title">{bodySection.title}</div>
            <div className="text">{renderDocument(bodySection.text.raw)}</div>
          </div>
        );

      default:
        return null;
    }
  });

  return <div className="container content">{content}</div>;
};

export default Content;
