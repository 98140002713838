import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import Button from './Button.js';
import CallForm from './CallForm.js';

function Hero({ isIndex, tag, primaryColor, data }) {
  const [isExpanded, toggleExpansion] = useState(false);

  const heroImage = data.image.svg.content;

  return (
    <div
      className={`hero sm:pt-20 pb-10 sm:pb-20 md:pt-20 pr-0 bg-${primaryColor}`}
    >
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-7">
          <div className="flex flex-col col-span-3">
            {!isIndex && <div className="tag mb-2">{tag}</div>}
            <div className="title">
              {documentToReactComponents(JSON.parse(data.title.raw))}
            </div>
            <div className="subtitle">
              {documentToReactComponents(JSON.parse(data.subheading.raw))}
            </div>
            {data.callToAction && (
              <div className="mt-10">
                <Button
                  text={data.callToAction}
                  path={data.callToActionUrl}
                  className="w-full mx-2 md:w-auto md:mx-0"
                />
              </div>
            )}
          </div>
          <div className="col-span-4">
            {/* <CallForm /> */}
            <div
              className={`${isIndex ? 'md:pl-10' : ''} mt-10 md:mt-0`}
              dangerouslySetInnerHTML={{ __html: heroImage }}
              alt={data.image.title}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

Hero.propTypes = {
  siteTitle: PropTypes.string,
};

Hero.defaultProps = {
  siteTitle: ``,
};

export default Hero;
